import { Injectable, OnDestroy, inject } from '@angular/core';
import ShortUniqueId from 'short-unique-id';

// firestore functions
// DocumentData, addDoc, updateDoc, deleteDoc, DocumentReference,
import {
  Firestore,
  doc,
  getDoc,
  setDoc,
  getDocs,
  updateDoc,
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  collectionGroup,
  limit,
  deleteDoc,
  and,
  or,
  Timestamp,
} from '@angular/fire/firestore';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { Observable, Subject, of } from 'rxjs';
//import { UserProfile, BusinessProfile, Business, BusinessUser} from "../interfaces/users-interface";
import {
  SellOffer,
  BuyOffer,
  BuyOrder,
  SellOrder,
  BuyContract,
  SellContract,
} from '../interfaces/orders-interface';

import { Commodities } from '../interfaces/base-interface';
import { SystemService } from './system.service';

@Injectable({
  providedIn: 'root',
})
export class OrdersService implements OnDestroy {
  private firestore: Firestore = inject(Firestore); // inject Cloud Firestore
  private functions = inject(Functions); // inject Cloud Functions

  sellOfferWatchers: any = {};
  buyOfferWatchers: any = {};
  buyOrdersWatcher: any;
  sellOrdersWatcher: any;
  myBuyOrdersWatcher: any;
  myBuyOrdersObserver!: Observable<BuyOrder[]>;
  mySellOrdersWatcher: any;
  mySellOrdersObserver!: Observable<SellOrder[]>;
  myOffersWatcher: any;
  myOffersObserver!: Observable<any[]>;

  public selectedCommodity: Commodities = {
    commodityID: '',
    name: '',
    code: '',
    description: '',
    specification: '',
    uom: '',
    conversions: [],
    testingOptions: [],
    acceptanceOptions: [],
    sortOrder: 0,
    status: '',
    imageURL: '',
  };
  public sellOrders: SellOrder[] = [];
  public buyOrders: BuyOrder[] = [];
  public myBuyOrders: BuyOrder[] = [];
  public mySellOrders: SellOrder[] = [];
  public myOffers: any[] = [];
  public numPosts: number = 0;

  constructor(private systemService: SystemService) {}

  ngOnDestroy() {
    // when manually subscribing to an observable remember to unsubscribe in ngOnDestroy
  }

  /**
   * The blankBuyOrder returns a blank Buy Order useful for initializing BuyOrder variables.
   * This is provided for consistency to ES6 checking
   * @returns BuyOrder
   */
  blankBuyOrder() {
    let nowDate = new Date();
    let blank = {
      orderID: '',
      buyerID: '',
      buyerBusinessID: '',
      commodity: this.systemService.blankCommodity(),
      stockpoint: this.systemService.blankStockpoint(),
      orderQuantity: 0,
      orderPrice: 0.0,
      tradePrice: 0.0,
      deliveryDate: nowDate,
      tradeStart: nowDate,
      tradeEnd: nowDate,
      testingSpecs: [],
      acceptanceSpecs: [],
      offers: [],
      status: '',
      buyerRequests: [],
      buyerCharges: [],
    };

    return blank as BuyOrder;
  }

  blankSellOrder() {
    let nowDate = new Date();
    let blank = {
      orderID: '',
      sellerID: '',
      sellerBusinessID: '',
      commodity: this.systemService.blankCommodity(),
      stockpoint: this.systemService.blankStockpoint(),
      orderQuantity: 0,
      orderPrice: 0.0,
      tradePrice: 0.0,
      deliveryDate: nowDate,
      tradeStart: nowDate,
      tradeEnd: nowDate,
      testingSpecs: [],
      acceptanceSpecs: [],
      offers: [],
      status: ' ',
      sellerRequests: [],
      sellerCharges: [],
    };

    return blank as SellOrder;
  }

  normalizeBuyOrder(orderInfo: BuyOrder): BuyOrder {
    delete orderInfo['buyOrderDocumentID'];
    // add other optional fields
    const blank = Object.assign(this.blankBuyOrder(), {
      updateTime: new Date(),
      createTime: new Date(),
    });
    const normalizedBuyOrder = {
      orderID: orderInfo.orderID || blank.orderID,
      buyerID: orderInfo.buyerID || blank.buyerID,
      buyerBusinessID: orderInfo.buyerBusinessID || blank.buyerBusinessID,
      commodity: orderInfo.commodity || blank.commodity,
      stockpoint: orderInfo.stockpoint || blank.stockpoint,
      orderQuantity: orderInfo.orderQuantity || blank.orderQuantity,
      orderPrice: orderInfo.orderPrice || blank.orderPrice,
      tradePrice: orderInfo.tradePrice || blank.tradePrice,
      deliveryDate: orderInfo.deliveryDate || blank.deliveryDate,
      tradeStart: orderInfo.tradeStart || blank.tradeStart,
      tradeEnd: orderInfo.tradeEnd || blank.tradeEnd,
      testingSpecs: orderInfo.testingSpecs || blank.testingSpecs,
      acceptanceSpecs: orderInfo.acceptanceSpecs || blank.acceptanceSpecs,
      offers: orderInfo.offers || blank.offers,
      status: orderInfo.status || blank.status,
      buyerRequests: orderInfo.buyerRequests || blank.buyerRequests,
      buyerCharges: orderInfo.buyerCharges || blank.buyerCharges,
      createTime: orderInfo.createTime || blank.createTime,
    };
    return normalizedBuyOrder;
  }

  normalizeSellOrder(orderInfo: SellOrder): SellOrder {
    delete orderInfo['sellOrderDocumentID'];

    // add other optional fields
    const blank = Object.assign(this.blankSellOrder(), {
      updateTime: new Date(),
      createTime: new Date(),
    });
    const normalizedSellOrder: SellOrder = {
      orderID: orderInfo.orderID || blank.orderID,
      sellerID: orderInfo.sellerID || blank.sellerID,
      sellerBusinessID: orderInfo.sellerBusinessID || blank.sellerBusinessID,
      commodity: orderInfo.commodity || blank.commodity,
      stockpoint: orderInfo.stockpoint || blank.stockpoint,
      orderQuantity: orderInfo.orderQuantity || blank.orderQuantity,
      orderPrice: orderInfo.orderPrice || blank.orderPrice,
      tradePrice: orderInfo.tradePrice || blank.tradePrice,
      deliveryDate: orderInfo.deliveryDate || blank.deliveryDate,
      tradeStart: orderInfo.tradeStart || blank.tradeStart,
      tradeEnd: orderInfo.tradeEnd || blank.tradeEnd,
      testingSpecs: orderInfo.testingSpecs || blank.testingSpecs,
      acceptanceSpecs: orderInfo.acceptanceSpecs || blank.acceptanceSpecs,
      offers: orderInfo.offers || blank.offers,
      status: orderInfo.status || blank.status,
      sellerRequests: orderInfo.sellerRequests || blank.sellerRequests,
      sellerCharges: orderInfo.sellerCharges || blank.sellerCharges,
      createTime: orderInfo.createTime || blank.createTime,
    };
    return normalizedSellOrder;
  }

  cleanBuyOrder(orderInfo: any) {
    delete orderInfo['sellOrderDocumentID'];
    delete orderInfo['createTime'];
    delete orderInfo['updateTime'];
    const blank = this.blankBuyOrder();
    Object.keys(orderInfo).forEach((key) => {
      if (key in blank) {
        // then good, keep it
      } else {
        delete orderInfo[key];
      }
    });
    return orderInfo;
  }
  cleanSellOrder(orderInfo: any) {
    delete orderInfo['sellOrderDocumentID'];
    delete orderInfo['createTime'];
    delete orderInfo['updateTime'];
    const blank = this.blankSellOrder();
    Object.keys(orderInfo).forEach((key) => {
      if (key in blank) {
        // then good, keep it
      } else {
        delete orderInfo[key];
      }
    });
    return orderInfo;
  }

  /**
   *  updateBuyOrder will update the buyOrder with the orderID
   * @param orderID updateBuyOrder
   * @params orderInfo
   * @returns
   */
  updateBuyOrder(orderDocumentID: string, orderInfo: any) {
    console.log('updateBuyOrder', orderDocumentID, orderInfo);
    const docpath = '/buyOrders/' + orderDocumentID;
    console.log('docPath: ', docpath);
    const buyOrderRef = doc(this.firestore, docpath);
    const updatedBuyOrder = this.cleanBuyOrder(orderInfo);

    // update the buyOrder with the new orderInfo
    return new Promise((resolve, reject) => {
      updateDoc(buyOrderRef, updatedBuyOrder as any).then(
        (updated) => {
          console.log('updateBuyOrder', updated);
          resolve({ success: true, msg: 'updated the buy order' });
        },
        (err) => {
          reject({
            success: false,
            msg: 'Error in updating buyOrder',
            error: err,
          });
        }
      );
    });
  }

  /**
   * updateSellOrder will update the sellOrder with the orderID
   * @param orderID
   * @param orderInfo
   * @returns
   */
  updateSellOrder(orderID: string, orderInfo: any) {
    console.log('updateSellOrder', orderID, orderInfo);
    // search firestore sellOrders collection for the orderID
    const docpath = '/sellOrders/' + orderInfo.sellOrderDocumentID;
    const sellOrdersRef = collection(this.firestore, docpath);
    const updatedSellOrder = this.cleanSellOrder(orderInfo);
    // update the order with the new orderInfo
    return new Promise((resolve, reject) => {
      console.log('docPath: ', docpath);
      const sellOrderRef = doc(this.firestore, docpath);
      updateDoc(sellOrderRef, updatedSellOrder as any).then(
        (updated) => {
          console.log('updateSellOrder', updated);
          resolve({ success: true, msg: 'updated the sell order' });
        },
        (err) => {
          reject({
            success: false,
            msg: 'Error in updating sellOrder',
            error: err,
          });
        }
      );
    });
  }

  submitBuyOrder(orderInfo: any) {
    const uid = new ShortUniqueId({ length: 6, dictionary: 'alphanum_upper' });
    orderInfo.orderID = uid.rnd();
    orderInfo.status = 'open';
    orderInfo.createTime = new Date();
    orderInfo.updateTime = new Date();

    const newBuyOrder = this.normalizeBuyOrder(orderInfo);
    return new Promise((resolve, reject) => {
      const buyOrdersRef = collection(this.firestore, 'buyOrders');
      const buyOrderObject = addDoc(buyOrdersRef, newBuyOrder).then(
        (newOrderRef) => {
          console.log('submitBuyOrder: created new OTB doc', newOrderRef.id);
          getDoc(newOrderRef).then(
            (newOrder) => {
              console.log('submitBuyOrder: got new OTB', newOrder.id);
              let data: any = newOrder.data();
              data['tradeStart'] = data['tradeStart'].toDate();
              data['tradeEnd'] = data['tradeEnd'].toDate();
              data['deliveryDate'] = data['deliveryDate'].toDate();
              data['createTime'] = data['createTime'].toDate();
              if (data['updateTime'] != undefined)
                data['updateTime'] = data['updateTime'].toDate();
              data['buyOrderDocumentID'] = newOrder.id;
              resolve({ success: true, orderInfo: data });
            },
            (err) => {
              reject({ success: false, error: err, orderInfo: orderInfo });
            }
          );
        },
        (err) => {
          reject({ success: false, error: err, orderInfo: orderInfo });
        }
      );
    });
  }

  submitSellOrder(orderInfo: any) {
    const uid = new ShortUniqueId({ length: 6, dictionary: 'alphanum_upper' });
    orderInfo.orderID = uid.rnd();
    orderInfo.status = 'open';
    orderInfo.createTime = new Date();
    orderInfo.updateTime = new Date();
    return new Promise((resolve, reject) => {
      const sellOrdersRef = collection(this.firestore, 'sellOrders');
      const newSellOrder = this.normalizeSellOrder(orderInfo);
      const sellOrderObject = addDoc(sellOrdersRef, newSellOrder).then(
        (newOrderRef) => {
          console.log('submitSellOrder: created new OTS doc', newOrderRef.id);
          getDoc(newOrderRef).then(
            (newOrder) => {
              console.log('submitSellOrder: got new OTS', newOrder.id);
              let data: any = newOrder.data();
              data['tradeStart'] = data['tradeStart'].toDate();
              data['tradeEnd'] = data['tradeEnd'].toDate();
              data['deliveryDate'] = data['deliveryDate'].toDate();
              data['createTime'] = data['createTime'].toDate();
              if (data['updateTime'])
                data['updateTime'] = data['updateTime'].toDate();
              data['sellOrderDocumentID'] = newOrder.id;
              resolve({ success: true, orderInfo: data });
            },
            (err) => {
              reject({ success: false, error: err, orderInfo: orderInfo });
            }
          );
        },
        (err) => {
          reject({ success: false, error: err, orderInfo: orderInfo });
        }
      );
    });
  }

  cancelBuyOrder(orderDocumentID: string) {
    return new Promise((resolve, reject) => {
      const docpath = '/buyOrders/' + orderDocumentID;
      console.log('docPath: ', docpath);
      const buyOrderRef = doc(this.firestore, docpath);
      deleteDoc(buyOrderRef).then(
        (deleted) => {
          console.log('cancelBuyOrder', deleted);
          resolve({ success: true, msg: 'deleted the buy order' });
        },
        (err) => {
          reject({
            success: false,
            msg: 'Error in deleting buyOrder',
            error: err,
          });
        }
      );
    });
  }

  cancelSellOrder(orderDocumentID: string) {
    return new Promise((resolve, reject) => {
      const docpath = '/sellOrders/' + orderDocumentID;
      console.log('docPath: ', docpath);
      const sellOrderRef = doc(this.firestore, docpath);
      deleteDoc(sellOrderRef).then(
        (deleted) => {
          console.log('cancelSellOrder', deleted);
          resolve({ success: true, msg: 'deleted the sell order' });
        },
        (err) => {
          reject({
            success: false,
            msg: 'Error in deleting sellOrder',
            error: err,
          });
        }
      );
    });
  }

  /**
   * The blankBuyOrder returns a blank Buy Order useful for initializing BuyOrder variables.
   * This is provided for consistency to ES6 checking
   * @returns BuyOrder
   */

  async getBuyOrderDocumentID(orderID: string) {
    const buyOrdersRef = collection(this.firestore, 'buyOrders');
    const q = query(buyOrdersRef, where('orderID', '==', orderID));
    const querySnapshot = await getDocs(q);
    let buyOrderID = '';
    querySnapshot.forEach((doc) => {
      buyOrderID = doc.id;
    });
    return Promise.resolve({ buyOrderDocID: buyOrderID });
  }

  async getSellOrderDocumentID(orderID: string) {
    const sellOrdersRef = collection(this.firestore, 'sellOrders');
    const q = query(sellOrdersRef, where('orderID', '==', orderID));
    const querySnapshot = await getDocs(q);
    let sellOrderID = '';
    querySnapshot.forEach((doc) => {
      sellOrderID = doc.id;
    });
    return Promise.resolve({ sellOrderDocID: sellOrderID });
  }

  blankBuyOffer(): BuyOffer {
    var blank: BuyOffer = {
      offerID: '',
      buyerID: '',
      buyerBusinessID: '',
      offerQuantity: 0,
      offerPrice: 0,
      offerType: 'buy',
      orderID: '',
      status: 'blank',
      buyerRequests: [],
      buyerCharges: [],
    };
    return blank;
  }

  blankSellOffer(): SellOffer {
    var blank: SellOffer = {
      offerID: '',
      sellerID: '',
      sellerBusinessID: '',
      offerQuantity: 0,
      offerPrice: 0,
      offerType: 'sell',
      orderID: '',
      status: 'blank',
      sellerRequests: [],
      sellerCharges: [],
    };
    return blank;
  }

  normalizeBuyOffer(offerInfo: BuyOffer): BuyOffer {
    delete offerInfo['buyOfferDocumentID'];
    // add other optional fields
    const blank = Object.assign(this.blankBuyOffer(), {
      updateTime: new Date(),
      createTime: new Date(),
    });
    const normalizedBuyOffer = {
      offerID: offerInfo.offerID || blank.offerID,
      buyerID: offerInfo.buyerID || blank.buyerID,
      buyerBusinessID: offerInfo.buyerBusinessID || blank.buyerBusinessID,
      offerQuantity: offerInfo.offerQuantity || blank.offerQuantity,
      offerPrice: offerInfo.offerPrice || blank.offerPrice,
      offerType: offerInfo.offerType || blank.offerType,
      orderID: offerInfo.orderID || blank.orderID,
      status: offerInfo.status || blank.status,
      buyerRequests: offerInfo.buyerRequests || blank.buyerRequests,
      buyerCharges: offerInfo.buyerCharges || blank.buyerCharges,
      createTime: offerInfo.createTime || blank.createTime,
    };
    return normalizedBuyOffer;
  }

  normalizeSellOffer(offerInfo: SellOffer): SellOffer {
    delete offerInfo['sellOfferDocumentID'];
    // add other optional fields
    const blank = Object.assign(this.blankSellOffer(), {
      updateTime: new Date(),
      createTime: new Date(),
    });
    const normalizedSellOffer = {
      offerID: offerInfo.offerID || blank.offerID,
      sellerID: offerInfo.sellerID || blank.sellerID,
      sellerBusinessID: offerInfo.sellerBusinessID || blank.sellerBusinessID,
      offerQuantity: offerInfo.offerQuantity || blank.offerQuantity,
      offerPrice: offerInfo.offerPrice || blank.offerPrice,
      offerType: offerInfo.offerType || blank.offerType,
      orderID: offerInfo.orderID || blank.orderID,
      status: offerInfo.status || blank.status,
      sellerRequests: offerInfo.sellerRequests || blank.sellerRequests,
      sellerCharges: offerInfo.sellerCharges || blank.sellerCharges,
      createTime: offerInfo.createTime || blank.createTime,
    };
    return normalizedSellOffer;
  }

  cleanBuyOffer(offerInfo: any): any {
    delete offerInfo['buyOfferDocumentID'];
    delete offerInfo['createTime'];
    delete offerInfo['updateTime'];
    const blank = this.blankBuyOffer();
    Object.keys(offerInfo).forEach((key) => {
      if (key in blank) {
        // then good, keep it
      } else {
        delete offerInfo[key];
      }
    });
    return offerInfo;
  }

  cleanSellOffer(offerInfo: any): any {
    delete offerInfo['sellOfferDocumentID'];
    delete offerInfo['createTime'];
    delete offerInfo['updateTime'];
    const blank = this.blankSellOffer();
    Object.keys(offerInfo).forEach((key) => {
      if (key in blank) {
        // then good, keep it
      } else {
        delete offerInfo[key];
      }
    });
    return offerInfo;
  }

  submitBuyOffer(orderDocumentID: string, offerInfo: BuyOffer) {
    console.log('submitBuyOffer', offerInfo);
    const uid = new ShortUniqueId({ length: 6, dictionary: 'alphanum_upper' });
    offerInfo.offerID = uid.rnd();
    return new Promise((resolve, reject) => {
      const path = 'sellOrders/' + orderDocumentID + '/offers';
      const offersRef = collection(this.firestore, path);
      const newBuyOffer = this.normalizeBuyOffer(offerInfo);
      addDoc(offersRef, newBuyOffer).then(
        (newOfferRef) => {
          console.log('got to add a new offer', newOfferRef);
          resolve({ success: true, msg: 'made a new offer' });
        },
        (err) => {
          console.log('failed to make a new offer', err);
          reject({
            success: false,
            msg: 'failed to make a new offer',
            err: err,
          });
        }
      );
    });
  }

  submitSellOffer(orderDocumentID: string, offerInfo: SellOffer) {
    console.log('submitSellOffer', offerInfo);
    const uid = new ShortUniqueId({ length: 6, dictionary: 'alphanum_upper' });
    offerInfo.offerID = uid.rnd();
    return new Promise((resolve, reject) => {
      const offersRef = collection(
        this.firestore,
        'buyOrders/' + orderDocumentID + '/offers'
      );
      const newSellOffer = this.normalizeSellOffer(offerInfo);
      addDoc(offersRef, newSellOffer).then(
        (newOfferRef) => {
          console.log('got to add a new offer', newOfferRef);
          resolve({ success: true, msg: 'made a new offer' });
        },
        (err) => {
          console.log('failed to make a new offer', err);
          reject({
            success: false,
            msg: 'failed to make a new offer',
            err: err,
          });
        }
      );
    });
  }

  updateBuyOffer(offerID: string, offerInfo: any) {
    const offersRef = collectionGroup(this.firestore, 'offers');
    const matchOffers = query(offersRef, where('offerID', '==', offerID));
    return new Promise((resolve, reject) => {
      console.log('updateBuyOffer: find offer', offerID);
      getDocs(matchOffers).then(
        (querySnapshot) => {
          let offerDocID = '';
          var offerDocRef: any;
          console.log('updateBuyOffer, got matching offers', querySnapshot);
          querySnapshot.forEach((doc) => {
            console.log('updateBuyOffer:', doc.id, '=>', doc.data());
            offerDocRef = doc.ref;
          });
          const updatedOfferInfo = this.cleanBuyOffer(offerInfo);
          updateDoc(offerDocRef, updatedOfferInfo as any).then(
            (updated) => {
              console.log('updateBuyOffer: updated sellOffer', offerDocID);
              resolve({ success: true, offerDocID: offerDocID });
            },
            (err) => {
              console.log('error while updating sellOffer', err);
              reject({ success: false, err: err });
            }
          );
        },
        (err) => {
          console.log('updateBuyOffer: error while gettingDocs', err);
        }
      );
    });
  }

  updateSellOffer(offerID: string, offerInfo: any) {
    const offersRef = collectionGroup(this.firestore, 'offers');
    const matchOffers = query(offersRef, where('offerID', '==', offerID));
    return new Promise((resolve, reject) => {
      console.log('updateSellOffer: find offer', offerID);
      getDocs(matchOffers).then((querySnapshot) => {
        let offerDocID = '';
        var offerDocRef: any;
        querySnapshot.forEach((doc) => {
          console.log('updateSellOffer:', doc.id, '=>', doc.data());
          offerDocRef = doc.ref;
        });
        const updatedOfferInfo = this.cleanSellOffer(offerInfo);
        updateDoc(offerDocRef, updatedOfferInfo as any).then(
          (updated) => {
            console.log('updateSellOffer: updated sellOffer', offerDocID);
            resolve({ success: true, offerDocID: offerDocID });
          },
          (err) => {
            console.log('error while updating sellOffer', err);
            reject({ success: false, err: err });
          }
        );
      });
    });
  }

  cancelOffer(offerID: string, orderID: string) {
    const offersRef = collectionGroup(this.firestore, 'offers');
    const matchOffers = query(
      offersRef,
      and(where('offerID', '==', offerID), where('orderID', '==', orderID))
    );
    return new Promise((resolve, reject) => {
      console.log('cancelOffer: find offer', offerID);
      getDocs(matchOffers).then((querySnapshot) => {
        let offerDocID = '';
        var offerDocRef: any;
        querySnapshot.forEach((doc) => {
          console.log('cancelOffer:', doc.id, '=>', doc.data());
          offerDocRef = doc.ref;
        });
        deleteDoc(offerDocRef).then(
          (updated) => {
            console.log('cancelOffer: deleting offer', offerDocID);
            resolve({ success: true, offerDocID: offerDocID });
          },
          (err) => {
            console.log('cancelOffer: error while deleting offer', err);
            reject({ success: false, err: err });
          }
        );
      });
    });
  }

  async getAllBuyOffers(orderDocumentID: string): Promise<Observable<any[]>> {
    const offersList = new Subject<any[]>();
    const path = 'sellOrders/' + orderDocumentID + '/offers';
    console.log('getAllBuyOffers', path);
    const buyOffersRef = collection(this.firestore, path);

    const offersSubscription = onSnapshot(buyOffersRef, (offers) => {
      console.log('getAllSellOffers: new snapshot', offers);
      const data = offers.docs.map((doc) =>
        Object.assign({}, doc.data(), { buyOfferID: doc.id })
      );
      offersList.next(data);
    });
    this.buyOfferWatchers[orderDocumentID] = offersSubscription;
    return offersList.asObservable();
  }

  async getAllSellOffers(orderDocumentID: string): Promise<Observable<any[]>> {
    const offersList = new Subject<any[]>();

    const path = 'buyOrders/' + orderDocumentID + '/offers';
    console.log('getAllSellOffers', path);
    const sellOffersRef = collection(this.firestore, path);

    const offersSubscription = onSnapshot(sellOffersRef, (offers) => {
      console.log('getAllSellOffers: new snapshot', offers);
      const data = offers.docs.map((doc) =>
        Object.assign({}, doc.data(), { sellOfferID: doc.id })
      );
      offersList.next(data);
    });
    this.sellOfferWatchers[orderDocumentID] = offersSubscription;
    return offersList.asObservable();
  }

  unsubscribeSellOffers(orderDocumentID: string) {
    const unsubscribe = this.sellOfferWatchers[orderDocumentID];
    unsubscribe();
  }
  unsubscribeBuyOffers(orderDocumentID: string) {
    const unsubscribe = this.buyOfferWatchers[orderDocumentID];
    unsubscribe();
  }

  async watchAllBuyOrders(
    codeList: string[],
    status: string = ''
  ): Promise<Observable<any[]>> {
    const allBuyOrders = new Subject<any[]>();
    // unsubscribe if there is an existing watcher
    if (this.buyOrdersWatcher !== undefined) {
      this.buyOrdersWatcher();
    }
    this.buyOrders = [];
    console.log('watchAllBuyOrders', codeList);
    const buyOrdersRef = collection(this.firestore, 'buyOrders');
    // if status != "" set query to also filter by status
    let q;
    let cutoff = new Date();
    // set the cutoff time to 3:00PM
    cutoff.setHours(15, 0, 0, 0);
    if (status != '') {
      q = query(
        buyOrdersRef,
        where('commodity.code', 'in', codeList),
        where('status', '==', status)
      ); //where('tradeEnd', '>=', cutoff),
    } else {
      q = query(buyOrdersRef, where('commodity.code', 'in', codeList)); //, where('tradeEnd', '>=', cutoff)
    }
    const buyOrdersSubscription = onSnapshot(q, (buyOrders) => {
      console.log('watchAllBuyOrders: new snapshot', buyOrders);
      const data = buyOrders.docs.map((doc) =>
        Object.assign({}, doc.data(), { buyOrderDocumentID: doc.id })
      );
      data.forEach((order, index) => {
        //convert tradeStart, tradeEnd, deliveryDate to Date
        data[index]['tradeStart'] = order['tradeStart'].toDate();
        data[index]['tradeEnd'] = order['tradeEnd'].toDate();
        data[index]['deliveryDate'] = order['deliveryDate'].toDate();
      });
      this.buyOrders = data as BuyOrder[];
      allBuyOrders.next(data);
    });
    this.buyOrdersWatcher = buyOrdersSubscription;
    return allBuyOrders.asObservable();
  }

  async watchAllSellOrders(
    codeList: string[],
    status: string = ''
  ): Promise<Observable<any[]>> {
    const allSellOrders = new Subject<any[]>();
    // unsubscribe if there is an existing watcher
    if (this.sellOrdersWatcher !== undefined) {
      this.sellOrdersWatcher();
    }
    this.sellOrders = [];
    console.log('watchAllSellOrders', codeList);
    const sellOrdersRef = collection(this.firestore, 'sellOrders');
    // if status != "" set query to also filter by status
    let q;
    let cutoff = new Date();
    // set the cutoff time to 3:00PM
    cutoff.setHours(15, 0, 0, 0);
    if (status != '') {
      q = query(
        sellOrdersRef,
        where('commodity.code', 'in', codeList),
        where('tradeEnd', '>=', cutoff),
        where('status', '==', status)
      );
    } else {
      q = query(
        sellOrdersRef,
        where('commodity.code', 'in', codeList),
        where('tradeEnd', '>=', cutoff)
      );
    }
    const sellOrdersSubscription = onSnapshot(q, (sellOrders) => {
      console.log('watchAllSellOrders: new snapshot', sellOrders);
      const data = sellOrders.docs.map((doc) =>
        Object.assign({}, doc.data(), { sellOrderDocumentID: doc.id })
      );

      data.forEach((order, index) => {
        //convert tradeStart, tradeEnd, deliveryDate to Date
        data[index]['tradeStart'] = order['tradeStart'].toDate();
        data[index]['tradeEnd'] = order['tradeEnd'].toDate();
        data[index]['deliveryDate'] = order['deliveryDate'].toDate();
      });
      console.log('watchAllSellOrders: updated dates', data);
      this.sellOrders = data as SellOrder[];
      allSellOrders.next(data);
    });
    this.sellOrdersWatcher = sellOrdersSubscription;
    return allSellOrders.asObservable();
  }

  async watchAllMyBuyOrders(
    buyerBusinessID: string
  ): Promise<Observable<any[]>> {
    const allBuyOrders = new Subject<any[]>();

    console.log('watchAllMyBuyOrders', buyerBusinessID);
    const buyOrdersRef = collection(this.firestore, 'buyOrders');
    const q = query(
      buyOrdersRef,
      where('buyerBusinessID', '==', buyerBusinessID)
    );
    const buyOrdersSubscription = onSnapshot(q, (buyOrders) => {
      console.log('watchAllMyBuyOrders: new snapshot', buyOrders);
      const data = buyOrders.docs.map((doc) =>
        Object.assign({}, doc.data(), { buyOrderDocumentID: doc.id })
      );
      data.forEach((order, index) => {
        //convert tradeStart, tradeEnd, deliveryDate to Date
        data[index]['tradeStart'] = order['tradeStart'].toDate();
        data[index]['tradeEnd'] = order['tradeEnd'].toDate();
        data[index]['deliveryDate'] = order['deliveryDate'].toDate();
      });
      this.myBuyOrders = data as BuyOrder[];
      this.setNumPosts();
      allBuyOrders.next(data);
    });
    this.myBuyOrdersWatcher = buyOrdersSubscription;
    this.myBuyOrdersObserver = allBuyOrders.asObservable();
    return allBuyOrders.asObservable();
  }

  async watchAllMySellOrders(
    sellerBusinessID: string
  ): Promise<Observable<any[]>> {
    const allSellOrders = new Subject<any[]>();

    console.log('watchAllMySellOrders', sellerBusinessID);
    const sellOrdersRef = collection(this.firestore, 'sellOrders');
    const q = query(
      sellOrdersRef,
      where('sellerBusinessID', '==', sellerBusinessID)
    );
    const sellOrdersSubscription = onSnapshot(q, (sellOrders) => {
      console.log('watchAllMySellOrders: new snapshot', sellOrders);
      const data = sellOrders.docs.map((doc) =>
        Object.assign({}, doc.data(), { sellOrderDocumentID: doc.id })
      );
      data.forEach((order, index) => {
        //convert tradeStart, tradeEnd, deliveryDate to Date
        data[index]['tradeStart'] = order['tradeStart'].toDate();
        data[index]['tradeEnd'] = order['tradeEnd'].toDate();
        data[index]['deliveryDate'] = order['deliveryDate'].toDate();
      });
      this.mySellOrders = data as SellOrder[];
      this.setNumPosts();
      allSellOrders.next(data);
    });
    this.mySellOrdersWatcher = sellOrdersSubscription;
    this.mySellOrdersObserver = allSellOrders.asObservable();
    return allSellOrders.asObservable();
  }

  setNumPosts() {
    this.numPosts =
      this.myBuyOrders.length + this.mySellOrders.length + this.myOffers.length;
  }

  /** Contract statii
   * 0 - new - new - for acceptance, not yet a fully signed contract
   * 1 - pending - forPending - contracted, but delivery date is still far away
   * 2 - payment - forPayment - buyer has to initiate payment prior to delivery by seller to stockpoint
   * 3 - receiving - forReceiving - order has been received by stockpoint
   * 4 - shipping - forShipping - lot is ready to be shipped to buyer
   * 5 - closed - fulfilled - lot has been shipped to buyer
   */
  /**
   * blankBuyContract creates a blankBuyContract
   * useful when creating the variable but not yet defining it
   * this is a utility function for ESLint compliance
   * @returns a blank Buy contract
   */

  blankBuyContract(): BuyContract {
    var blank: BuyContract = {
      contractID: '',
      orderID: '',
      buyerID: '',
      buyerBusinessID: '',
      sellerID: '',
      sellerBusinessID: '',
      commodity: this.systemService.blankCommodity(),
      contractQuantity: 0,
      contractPrice: 0,
      contractType: 'buy',
      deliveryDate: new Date(),
      stockpoint: this.systemService.blankStockpoint(),
      testingSpecs: [],
      acceptanceSpecs: [],
      sellerRequests: [],
      sellerCharges: [],
      buyerRequests: [],
      buyerCharges: [],
      lakalCharges: [],
      paymentTerms: '',
      status: 'blank',
    };
    return blank;
  }

  blankSellContract(): SellContract {
    var blank: BuyContract = {
      contractID: '',
      orderID: '',
      buyerID: '',
      buyerBusinessID: '',
      sellerID: '',
      sellerBusinessID: '',
      commodity: this.systemService.blankCommodity(),
      contractQuantity: 0,
      contractPrice: 0,
      contractType: 'sell',
      deliveryDate: new Date(),
      stockpoint: this.systemService.blankStockpoint(),
      testingSpecs: [],
      acceptanceSpecs: [],
      sellerRequests: [],
      sellerCharges: [],
      buyerRequests: [],
      buyerCharges: [],
      lakalCharges: [],
      paymentTerms: '',
      status: 'blank',
    };
    return blank;
  }

  normalizeBuyContract(contractInfo: BuyContract): BuyContract {
    delete contractInfo['contractDocumentID'];
    // add other optional fields
    const blank = Object.assign(this.blankBuyContract(), {
      updateTime: new Date(),
      createTime: new Date(),
    });
    let normalizedBuyContract = {
      contractID: contractInfo.contractID || blank.contractID,
      orderID: contractInfo.orderID || blank.orderID,
      buyerID: contractInfo.buyerID || blank.buyerID,
      buyerBusinessID: contractInfo.buyerBusinessID || blank.buyerBusinessID,
      sellerID: contractInfo.sellerID || blank.sellerID,
      sellerBusinessID: contractInfo.sellerBusinessID || blank.sellerBusinessID,
      commodity: contractInfo.commodity || blank.commodity,
      contractQuantity: contractInfo.contractQuantity || blank.contractQuantity,
      contractPrice: contractInfo.contractPrice || blank.contractPrice,
      contractType: contractInfo.contractType || blank.contractType,
      deliveryDate: contractInfo.deliveryDate || blank.deliveryDate,
      stockpoint: contractInfo.stockpoint || blank.stockpoint,
      testingSpecs: contractInfo.testingSpecs || blank.testingSpecs,
      acceptanceSpecs: contractInfo.acceptanceSpecs || blank.acceptanceSpecs,
      sellerRequests: contractInfo.sellerRequests || blank.sellerRequests,
      sellerCharges: contractInfo.sellerCharges || blank.sellerCharges,
      buyerRequests: contractInfo.buyerRequests || blank.buyerRequests,
      buyerCharges: contractInfo.buyerCharges || blank.buyerCharges,
      lakalCharges: contractInfo.lakalCharges || blank.lakalCharges,
      paymentTerms: contractInfo.paymentTerms || blank.paymentTerms,
      status: contractInfo.status || blank.status,
      createTime: contractInfo.createTime || blank.createTime,
    };
    // add back the conditional fields
    if (contractInfo.signedBuyer) {
      Object.assign(normalizedBuyContract, {
        signedBuer: contractInfo.signedBuyer,
      });
    }
    if (contractInfo.signedSeller) {
      Object.assign(normalizedBuyContract, {
        signedSeller: contractInfo.signedSeller,
      });
    }
    return normalizedBuyContract;
  }

  normalizeSellContract(contractInfo: SellContract): SellContract {
    delete contractInfo['contractDocumentID'];
    // add other optional fields
    const blank = Object.assign(this.blankSellContract(), {
      updateTime: new Date(),
      createTime: new Date(),
    });
    let normalizedSellContract = {
      contractID: contractInfo.contractID || blank.contractID,
      orderID: contractInfo.orderID || blank.orderID,
      buyerID: contractInfo.buyerID || blank.buyerID,
      buyerBusinessID: contractInfo.buyerBusinessID || blank.buyerBusinessID,
      sellerID: contractInfo.sellerID || blank.sellerID,
      sellerBusinessID: contractInfo.sellerBusinessID || blank.sellerBusinessID,
      commodity: contractInfo.commodity || blank.commodity,
      contractQuantity: contractInfo.contractQuantity || blank.contractQuantity,
      contractPrice: contractInfo.contractPrice || blank.contractPrice,
      contractType: contractInfo.contractType || blank.contractType,
      deliveryDate: contractInfo.deliveryDate || blank.deliveryDate,
      stockpoint: contractInfo.stockpoint || blank.stockpoint,
      testingSpecs: contractInfo.testingSpecs || blank.testingSpecs,
      acceptanceSpecs: contractInfo.acceptanceSpecs || blank.acceptanceSpecs,
      sellerRequests: contractInfo.sellerRequests || blank.sellerRequests,
      sellerCharges: contractInfo.sellerCharges || blank.sellerCharges,
      buyerRequests: contractInfo.buyerRequests || blank.buyerRequests,
      buyerCharges: contractInfo.buyerCharges || blank.buyerCharges,
      lakalCharges: contractInfo.lakalCharges || blank.lakalCharges,
      paymentTerms: contractInfo.paymentTerms || blank.paymentTerms,
      status: contractInfo.status || blank.status,
      createTime: contractInfo.createTime || blank.createTime,
    };
    // add back the conditional fields
    if (contractInfo.signedBuyer) {
      Object.assign(normalizedSellContract, {
        signedBuer: contractInfo.signedBuyer,
      });
    }
    if (contractInfo.signedSeller) {
      Object.assign(normalizedSellContract, {
        signedSeller: contractInfo.signedSeller,
      });
    }
    return normalizedSellContract;
  }

  /**
   * Draft Buy Contract will create a BuyContract object from an offer.
   * It is intended to be used or initate only by a buyer of an OTB. A Market Maker.
   * The output of the BuyContract is intended to be sent to createBuyContract
   * @param orderInfo
   * @param offerInfo
   * @returns
   */
  draftBuyContract(orderInfo: BuyOrder, offerInfo: SellOffer): BuyContract {
    var draft: BuyContract = {
      contractID: orderInfo.orderID + '-' + offerInfo.offerID,
      orderID: orderInfo.orderID,
      buyerID: orderInfo.buyerID,
      buyerBusinessID: orderInfo.buyerBusinessID,
      sellerID: offerInfo.sellerID,
      sellerBusinessID: offerInfo.sellerBusinessID,
      commodity: orderInfo.commodity,
      contractQuantity: offerInfo.offerQuantity,
      contractPrice: offerInfo.offerPrice,
      contractType: 'buy',
      deliveryDate: orderInfo.deliveryDate,
      stockpoint: orderInfo.stockpoint,
      testingSpecs: orderInfo.testingSpecs,
      acceptanceSpecs: orderInfo.acceptanceSpecs,
      sellerRequests: offerInfo.sellerRequests,
      sellerCharges: offerInfo.sellerCharges,
      buyerRequests: orderInfo.buyerRequests || [],
      buyerCharges: orderInfo.buyerCharges || [],
      lakalCharges: [],
      paymentTerms: '',
      status: 'draft',
    };
    return this.normalizeBuyContract(draft);
  }

  /**
   * Draft Sell Contract will create a SellContract object from an offer.
   * It is intended to be used or initate only by a buyer of an OTB. A Market Maker.
   * The output of the SellContract is intended to be sent to createSellContract
   * @param orderInfo
   * @param offerInfo
   * @returns
   */

  draftSellContract(orderInfo: SellOrder, offerInfo: BuyOffer): SellContract {
    var draft: SellContract = {
      contractID: orderInfo.orderID + '-' + offerInfo.offerID,
      orderID: orderInfo.orderID,
      sellerID: orderInfo.sellerID,
      sellerBusinessID: orderInfo.sellerBusinessID,
      buyerID: offerInfo.buyerID,
      buyerBusinessID: offerInfo.buyerBusinessID,
      commodity: orderInfo.commodity,
      contractQuantity: offerInfo.offerQuantity,
      contractPrice: offerInfo.offerPrice,
      contractType: 'sell',
      deliveryDate: orderInfo.deliveryDate,
      stockpoint: orderInfo.stockpoint,
      testingSpecs: orderInfo.testingSpecs,
      acceptanceSpecs: orderInfo.acceptanceSpecs,
      sellerRequests: orderInfo.sellerRequests || [],
      sellerCharges: orderInfo.sellerCharges || [],
      buyerRequests: offerInfo.buyerRequests,
      buyerCharges: offerInfo.buyerCharges,
      lakalCharges: [],
      paymentTerms: '',
      status: 'draft',
    };
    return this.normalizeSellContract(draft);
  }

  /**
   * Create Buy Contract will take a BuyContract object and simply save it to firestore
   * @param buyContract
   * @returns
   */
  createBuyContract(buyContract: BuyContract): Promise<any> {
    console.log('createBuyContract');
    return new Promise((resolve, reject) => {
      const buyContractsRef = collection(this.firestore, 'buyContracts');
      const newBuyContract = this.normalizeBuyContract(buyContract);
      const buyContractObject = addDoc(buyContractsRef, newBuyContract).then(
        (newDocRef) => {
          console.log('created new BuyContract', newDocRef.id);
          getDoc(newDocRef).then(
            (newBuyContract) => {
              console.log('got newBuyContractID', newBuyContract.id);
              resolve({
                success: true,
                contractDocumentID: newBuyContract.id,
                buyContract: Object.assign({}, newBuyContract.data(), {
                  contractDocumentID: newBuyContract.id,
                }),
              });
            },
            (err) => {
              console.log('could not retrieve new contract');
              reject({
                success: false,
                msg: 'could not get new buy contract',
                err: err,
              });
            }
          );
        }
      );
    });
  }

  /**
   * Create Sell Contract will take a SellContract object and simply save it to firestore
   * @param sellContract
   * @returns
   */
  createSellContract(sellContract: SellContract): Promise<any> {
    console.log('createSellContract');
    return new Promise((resolve, reject) => {
      const sellContractsRef = collection(this.firestore, 'sellContracts');
      const sellContractObject = addDoc(sellContractsRef, sellContract).then(
        (newDocRef) => {
          console.log('created new SellContract', newDocRef.id);
          getDoc(newDocRef).then(
            (newSellContract) => {
              console.log('got newSellContractID', newSellContract.id);
              resolve({
                success: true,
                contractDocumentID: newSellContract.id,
                sellContract: Object.assign({}, newSellContract.data(), {
                  contractDocumentID: newSellContract.id,
                }),
              });
            },
            (err) => {
              console.log('could not retrieve new contract');
              reject({
                success: false,
                msg: 'could not get new sell contract',
                err: err,
              });
            }
          );
        }
      );
    });
  }

  /**
   * getBuyContract will retrieve a buyContract from firestore using the orderID and offerID
   * @param orderInfo
   * @param offerInfo
   * @returns
   */
  getBuyContract(orderID: string, offerID: string): Promise<any> {
    const contractID = orderID + '-' + offerID;
    const buyContractsRef = collection(this.firestore, 'buyContracts');
    const q = query(buyContractsRef, where('contractID', '==', contractID));
    return new Promise((resolve, reject) => {
      getDocs(q).then(
        (buyContractsSnapshot) => {
          let buyContract: any = {};
          buyContractsSnapshot.forEach((doc) => {
            buyContract = Object.assign({}, doc.data(), {
              contractDocumentID: doc.id,
            });
          });
          // convert signedBuyer and signedSeller to Date objects
          if (buyContract['signedBuyer']) {
            buyContract.signedBuyer = buyContract.signedBuyer.toDate();
          }
          if (buyContract['signedSeller']) {
            buyContract.signedSeller = buyContract.signedSeller.toDate();
          }
          resolve({ success: true, buyContract: buyContract });
        },
        (err) => {
          console.log('error while getting buyContract');
          reject({ success: false, error: err });
        }
      );
    });
  }

  getSellContract(orderID: string, offerID: string): Promise<any> {
    const contractID = orderID + '-' + offerID;
    const sellContractsRef = collection(this.firestore, 'sellContracts');
    console.log('getSellContract', contractID);
    const q = query(sellContractsRef, where('contractID', '==', contractID));
    return new Promise((resolve, reject) => {
      getDocs(q).then(
        (sellContractsSnapshot) => {
          let sellContract: any = {};
          sellContractsSnapshot.forEach((doc) => {
            sellContract = Object.assign({}, doc.data(), {
              contractDocumentID: doc.id,
            });
          });

          // convert signedBuyer and signedSeller to Date objects
          if (sellContract.signedBuyer) {
            sellContract.signedBuyer = sellContract.signedBuyer.toDate();
          }
          if (sellContract.signedSeller) {
            sellContract.signedSeller = sellContract.signedSeller.toDate();
          }
          resolve({ success: true, sellContract: sellContract });
        },
        (err) => {
          console.log('error while getting sellContract');
          reject({ success: false, error: err });
        }
      );
    });
  }

  /**
   * Buyer will now sign the BuyContract with BuyContractID
   * Buyer is the market maker, final signer makes the contract pending
   * @param buyContractID
   * @returns
   */
  buyerSignBuyContract(buyContractID: string, params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const buyContractRef = doc(
        this.firestore,
        'buyContracts/' + buyContractID
      );
      updateDoc(buyContractRef, {
        signedBuyer: new Date(),
        status: 'pending',
        ...params,
      }).then(
        (result) => {
          console.log(
            'buyerSignBuyContract: updated BuyContract',
            buyContractID,
            result
          );
          resolve({ success: true, signed: result });
        },
        (err) => {
          console.log(
            'buyerSignBuyContract: error while updating BuyContract',
            buyContractID,
            err
          );
          reject({
            success: false,
            msg: 'error while signing buyContract',
            err: err,
          });
        }
      );
    });
  }

  /**
   * Seller will now sign the BuyContract with BuyContractID
   * Seller is the market taker, but signs the contract first so contract is open
   * @param buyContractID
   * @returns
   */
  sellerSignBuyContract(buyContractID: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const buyContractRef = doc(
        this.firestore,
        'buyContracts/' + buyContractID
      );
      updateDoc(buyContractRef, {
        signedSeller: new Date(),
        status: 'open',
      }).then(
        (result) => {
          console.log(
            'sellerSignBuyContract: updated BuyContract',
            buyContractID,
            result
          );
          resolve({ success: true, signed: result });
        },
        (err) => {
          console.log(
            'sellerSignBuyContract: error while updating BuyContract',
            buyContractID,
            err
          );
          reject({
            success: false,
            msg: 'error while signing buyContract',
            err: err,
          });
        }
      );
    });
  }

  /**
   * Seller will now sign the SellContract with SellContractID
   * Seller is the market maker, so contract is final when seller signs, contract becomes pending
   * @param sellContractID
   * @returns
   */
  sellerSignSellContract(sellContractID: string, params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const sellContractRef = doc(
        this.firestore,
        'sellContracts/' + sellContractID
      );
      updateDoc(sellContractRef, {
        signedSeller: new Date(),
        status: 'pending',
        ...params,
      }).then(
        (result) => {
          console.log(
            'sellerSignSellContract: updated SellContract',
            sellContractID,
            result
          );
          resolve({ success: true, signed: result });
        },
        (err) => {
          console.log(
            'sellerSignSellContract: error while updating SellContract',
            sellContractID,
            err
          );
          reject({
            success: false,
            msg: 'error while signing SellContract',
            err: err,
          });
        }
      );
    });
  }

  /**
   * Buyer will now sign the SellContract with SellContractID
   * Buyer is the market taker, but signs the contract first, contract is open
   * @param sellContractID
   * @returns
   */
  buyerSignSellContract(sellContractID: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const sellContractRef = doc(
        this.firestore,
        'sellContracts/' + sellContractID
      );
      updateDoc(sellContractRef, {
        signedBuyer: new Date(),
        status: 'open',
      }).then(
        (result) => {
          console.log(
            'buyerSignSellContract: updated SellContract',
            sellContractID,
            result
          );
          resolve({ success: true, signed: result });
        },
        (err) => {
          console.log(
            'buyerSignSellContract: error while updating SellContract',
            sellContractID,
            err
          );
          reject({
            success: false,
            msg: 'error while signing SellContract',
            err: err,
          });
        }
      );
    });
  }

  cancelBuyContract(contractID: string) {
    return new Promise((resolve, reject) => {
      const buyContractsRef = collection(this.firestore, 'buyContracts');
      const q = query(
        buyContractsRef,
        and(
          where('contractID', '==', contractID),
          where('status', '==', 'open')
        ),
        limit(1)
      );
      getDocs(q).then(
        (querySnapshot) => {
          let buyContractDocID = '';
          querySnapshot.forEach((doc) => {
            buyContractDocID = doc.id;
          });
          const docpath = '/buyContracts/' + buyContractDocID;

          console.log('docPath: ', docpath);
          const buyContractRef = doc(this.firestore, docpath);
          deleteDoc(buyContractRef).then(
            (deleted) => {
              console.log('cancelBuyContract', deleted);
              resolve({ success: true, msg: 'deleted the buy contract' });
            },
            (err) => {
              reject({
                success: false,
                msg: 'Error in deleting buyContract',
                error: err,
              });
            }
          );
        },
        (err) => {
          console.log('error while updating buy contract ');
        }
      );
    });
  }

  cancelSellContract(contractID: string) {
    return new Promise((resolve, reject) => {
      const buyContractsRef = collection(this.firestore, 'sellContracts');
      const q = query(
        buyContractsRef,
        and(
          where('contractID', '==', contractID),
          where('status', '==', 'open')
        ),
        limit(1)
      );
      getDocs(q).then(
        (querySnapshot) => {
          let sellContractDocID = '';
          querySnapshot.forEach((doc) => {
            sellContractDocID = doc.id;
          });
          if (sellContractDocID === '') {
            reject({
              success: false,
              msg: 'Error in deleting sellContract',
              error: 'No such contract',
            });
          } else {
            const docpath = '/sellContracts/' + sellContractDocID;
            console.log('docPath: ', docpath);
            const sellContractRef = doc(this.firestore, docpath);

            deleteDoc(sellContractRef).then(
              (deleted) => {
                console.log('cancelSellContract', deleted);
                resolve({ success: true, msg: 'deleted the sell contract' });
              },
              (err) => {
                reject({
                  success: false,
                  msg: 'Error in deleting sellContract',
                  error: err,
                });
              }
            );
          }
        },
        (err) => {
          console.log('error while updating buy contract ');
        }
      );
    });
  }

  sellerUpdateBuyContract(contractID: string, params: any) {
    return new Promise((resolve, reject) => {
      const buyContractsRef = collection(this.firestore, 'buyContracts');
      const q = query(
        buyContractsRef,
        and(
          where('contractID', '==', contractID),
          where('status', '==', 'open')
        ),
        limit(1)
      );
      getDocs(q).then(
        (querySnapshot) => {
          let buyContractDocID = '';
          querySnapshot.forEach((doc) => {
            buyContractDocID = doc.id;
          });
          const docpath = '/buyContracts/' + buyContractDocID;
          console.log('docPath: ', docpath);
          const buyContractRef = doc(this.firestore, docpath);

          updateDoc(buyContractRef, params).then(
            (updated) => {
              console.log('sellerUpdateBuyContract', updated);
              resolve({ success: true, msg: 'updated the buy contract' });
            },
            (err) => {
              reject({
                success: false,
                msg: 'Error in updating buyContract',
                error: err,
              });
            }
          );
        },
        (err) => {
          console.log('error while updating buy contract ');
        }
      );
    });
  }

  buyerUpdateSellContract(contractID: string, params: any) {
    console.log('buyerUpdateSellContract', contractID, params);
    return new Promise((resolve, reject) => {
      const sellContractsRef = collection(this.firestore, 'sellContracts');
      const q = query(
        sellContractsRef,
        and(
          where('contractID', '==', contractID),
          where('status', '==', 'open')
        ),
        limit(1)
      );
      getDocs(q).then(
        (querySnapshot) => {
          let sellContractDocID = '';
          querySnapshot.forEach((doc) => {
            sellContractDocID = doc.id;
          });
          const docpath = '/sellContracts/' + sellContractDocID;
          console.log('docPath: ', docpath);
          const sellContractRef = doc(this.firestore, docpath);

          updateDoc(sellContractRef, params).then(
            (updated) => {
              console.log('buyerUpdateSellContract', updated);
              resolve({ success: true, msg: 'updated the sell contract' });
            },
            (err) => {
              reject({
                success: false,
                msg: 'Error in updating sellContract',
                error: err,
              });
            }
          );
        },
        (err) => {
          console.log('error while updating sell contract ');
        }
      );
    });
  }

  // getAllMyOffers(userID: string) {
  //   console.log('getAllMyOffers', userID);
  //   const offersRef = collectionGroup(this.firestore, 'offers');
  //   const myOffers = query(offersRef, where('sellerID', '==', userID));
  //   return new Promise((resolve, reject) => {
  //     console.log('getAllMyOffers run query');
  //     getDocs(myOffers).then(
  //       (querySnapshot) => {
  //         let sellOffers: any[] = [];
  //         let buyOffers: any[] = [];
  //         querySnapshot.forEach((doc) => {
  //           console.log(doc.id, '=>');
  //           let offer = doc.data();
  //           if (offer['sellerID'] !== undefined) {
  //             sellOffers.push(
  //               Object.assign({}, offer, { sellOfferID: doc.id })
  //             );
  //           } else {
  //             buyOffers.push(Object.assign({}, offer, { buyOfferID: doc.id }));
  //           }
  //         });
  //         resolve({
  //           success: true,
  //           sellOffers: sellOffers,
  //           buyOffers: buyOffers,
  //         });
  //       },
  //       (err) => {
  //         console.log('getAllMyOffers: did not get any', err);
  //         reject({ success: false, err: err });
  //       }
  //     );
  //   });
  // }

  checkOfferType(offer: any) {
    if (offer['sellerID'] !== undefined) {
      return 'sell';
    } else {
      return 'buy';
    }
  }

  async watchAllMyOffers(businessID: string) {
    console.log('getAllMyOffers', businessID);
    const allOffers = new Subject<any[]>();
    const offersRef = collectionGroup(this.firestore, 'offers');
    const myOffers = query(
      offersRef,
      or(
        where('sellerBusinessID', '==', businessID),
        where('buyerBusinessID', '==', businessID)
      )
    );

    const myOffersSubscription = onSnapshot(myOffers, (offers) => {
      console.log('watchAllMyOffers: new snapshot', offers);
      const data = offers.docs.map((doc) =>
        Object.assign({}, doc.data(), { offerDocumentID: doc.id })
      );
      data.forEach((offer: any) => {
        if (!offer['offerType']) {
          offer['offerType'] = this.checkOfferType(offer);
        }
        if (offer['offerType'] == 'sell') {
          offer['sellOfferDocumentID'] = offer['offerDocumentID'];
          delete offer['sellOfferID']; // for straggling records
        } else {
          offer['buyOfferDocumentID'] = offer['offerDocumentID'];
          delete offer['buyOfferID']; // for straggling records
        }
        delete offer['offerDocumentID'];
      });
      this.myOffers = data;
      this.setNumPosts();
      allOffers.next(data);
    });

    this.myOffersWatcher = myOffersSubscription;
    this.myOffersObserver = allOffers.asObservable();
    return allOffers.asObservable();
  }

  getBuyOrderByID(orderID: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const buyOrdersRef = collection(this.firestore, 'buyOrders');
      const q = query(buyOrdersRef, where('orderID', '==', orderID), limit(1));
      getDocs(q).then(
        (querySnapshot) => {
          let buyOrderDocID = '';
          querySnapshot.forEach((doc) => {
            buyOrderDocID = doc.id;
          });
          const docpath = '/buyOrders/' + buyOrderDocID;
          console.log('docPath: ', docpath);
          const buyOrderRef = doc(this.firestore, docpath);
          getDoc(buyOrderRef).then(
            (buyOrder) => {
              let data: any = buyOrder.data();
              data['tradeStart'] = data['tradeStart'].toDate();
              data['tradeEnd'] = data['tradeEnd'].toDate();
              data['deliveryDate'] = data['deliveryDate'].toDate();
              resolve({
                success: true,
                msg: 'got the buy order',
                buyOrder: data,
              });
            },
            (err) => {
              reject({
                success: false,
                msg: 'Error in getting buyOrder',
                error: err,
              });
            }
          );
        },
        (err) => {
          console.log('error while getting docs ');
        }
      );
    });
  }

  getSellOrderByID(orderID: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const sellOrdersRef = collection(this.firestore, 'sellOrders');
      const q = query(sellOrdersRef, where('orderID', '==', orderID), limit(1));
      getDocs(q).then(
        (querySnapshot) => {
          let sellOrderDocID = '';
          querySnapshot.forEach((doc) => {
            sellOrderDocID = doc.id;
          });
          const docpath = '/sellOrders/' + sellOrderDocID;
          console.log('docPath: ', docpath);
          const sellOrderRef = doc(this.firestore, docpath);
          getDoc(sellOrderRef).then(
            (sellOrder) => {
              let data: any = sellOrder.data();
              data['tradeStart'] = data['tradeStart'].toDate();
              data['tradeEnd'] = data['tradeEnd'].toDate();
              data['deliveryDate'] = data['deliveryDate'].toDate();
              resolve({
                success: true,
                msg: 'got the sell order',
                sellOrder: data,
              });
            },
            (err) => {
              reject({
                success: false,
                msg: 'Error in getting sellOrder',
                error: err,
              });
            }
          );
        },
        (err) => {
          console.log('error while getting docs ');
        }
      );
    });
  }
}
